<template>
  <v-container grid-list-xl>
    <v-layout wrap justify-space-between>
      <v-flex xs12 md12>
        <v-alert :value="alert" type="success">Başarıyla kaldedildi.</v-alert>
      </v-flex>
      <v-flex xs12 md12>
        <v-text-field v-model="slotEvent.i" label="Id" disabled></v-text-field>
        <v-switch
          v-model="slotEvent.isActive"
          :label="`Is Active`"
          hide-slotEvents
        ></v-switch>
        <v-text-field
          v-model="slotEvent.tm"
          label="Theme Name"
          hide-slotEvents
        ></v-text-field>
        <v-text-field
          v-model="slotEvent.o"
          label="Order"
          hide-slotEvents
        ></v-text-field>
        <v-text-field
          v-model="slotEvent.t"
          label="Finish time as seconds"
          hide-slotEvents
        ></v-text-field>
        <v-text-field
          v-model="slotEvent.pr"
          label="Priority"
          hide-slotEvents
        ></v-text-field>
        <br />
        <template>
  <div>
    <br>
    <v-btn color="success" v-if="!isAddClicked" @click="addItem">Add new mission step</v-btn>
    <v-btn color="success" v-else @click="saveItems">Save new mission step</v-btn>
    <form v-if="isAddClicked ">
      <br>
      <label for="title">Name:</label>
      <input type="text" v-model="mission.n">
      <br>
      <label for="title">Description:</label>
      <input type="text" v-model="mission.dc">
      <br>
      <label for="title">Background Picture:</label>
      <input type="text" v-model="mission.bp">
      <br>
      <label for="target">Target:</label>
      <input type="target" v-model="mission.ta">
      <br>
      <v-switch
          v-model="mission.ir"
          :label="`Required Star`"
          hide-slotEvents
        ></v-switch>
      <br>
      <v-autocomplete
          v-model="mission.t"
          label="Rewards Free Reward Type"
          :counter="max"
          :items="rewardType"
          item-value="key"
          item-text="value"
          placeholder="Select..."
        ></v-autocomplete>
      <label for="chip">Chip:</label>
      <input type="chip" v-model="mission.e.chip">
      <br>
      <label for="gems">Gems:</label>
      <input type="gems" v-model="mission.e.gems">
      <br>
      <label for="bingo">Bingo ball:</label>
      <input type="bingo" v-model="mission.e.bingo">
    </form>


    <form v-if="this.$route.params.id">
    <div v-for="(item, index) in this.m" :key="index">
            -------------------------------------------
            <br>
      <label for="title">Name:</label>
      <input style="color: white; margin-left: 10px;" type="text" v-model="item.n">
      <br>
      <label for="title">Description:</label>
      <input style="color: white; margin-left: 10px;" type="text" v-model="item.dc">
      <br>
      <label for="title">Background Picture:</label>
      <input style="color: white; margin-left: 10px;" type="text" v-model="item.bp">
      <br>
      <label for="target">Target:</label>
      <input style="color: white; margin-left: 10px;" type="target" v-model="item.ta">
      <br>
      <v-switch
          v-model="item.ir"
          :label="`Required Star`"
          hide-slotEvents
        ></v-switch>
      <br>
      <v-autocomplete
          v-model="item.t"
          label="Rewards Type"
          :counter="max"
          :items="rewardType"
          item-value="key"
          item-text="value"
          placeholder="Select..."
        ></v-autocomplete>
      <label for="chip">Chip:</label>
      <input style="color: white; margin-left: 10px;" type="chip" v-model="item.e.c">
      <br>
      <label for="gems">Gems:</label>
      <input style="color: white; margin-left: 10px;" type="gems" v-model="item.e.g">
      <br>
      <label for="bingo">Bingo ball:</label>
      <input style="color: white; margin-left: 10px;" type="bingo" v-model="item.e.b">
      <br>
      -------------------------------------------
    </div>
    </form>
    <br>
    <br>
  </div>
</template>
      </v-flex>
      <v-btn color="success" @click="saveOrCreate">Save</v-btn>
    </v-layout>
  </v-container>
</template>
<script>
import rewardType from "@/lib/rewardType";
import router from "@/router";
export default {
  data: () => ({
    slotEvent: {},
    reward:{},
    m:[],
    mission:{e:{}},
    rewardType: rewardType,
    alert: false,
    max: 120,
    menu: false,
    modal: false,
    menu2: false,
    isAddClicked: false,
  }),

  methods: {
    addItem() {
      this.isAddClicked = true; 
    },
    saveItems() {
      this.m.push(this.mission);
      this.isAddClicked = false; 
    },
    async saveOrCreate() {
      this.slotEvent.m = this.m;
       // eslint-disable-next-line no-console
    console.log("m",this.m);
      if (this.$route.params.id) {
        await this.$store.dispatch("slotEvents/put", this.slotEvent);
      } else{
      await this.$store.dispatch("slotEvents/post", this.slotEvent);
      router.push("/slotEvents");
      }

    },
    async initialize() {
      if (this.$route.params.id) {
        await this.$store.dispatch("slotEvents/getById", this.$route.params.id);
        this.slotEvent = this.$store.state.slotEvents.detail;
        this.m=this.slotEvent.m;
            // eslint-disable-next-line no-console
    console.log("m",this.m);
      }
    },
  },
  validateField() {
    this.$refs.form.validate();
  },
  async mounted() {
    await this.initialize();
  },
};
</script>
